h4.Navb-tittle {
    color: white;
}

#navBakcground {
    background-color: #0e3057 !important;
}

/* .floatt {
    z-index: 1045;
    position: fixed;
    top: 0;
    width:100%;
} */

.dropdown-toggle::after {
    display:none !important;
}

#input-group-dropdown-1 {
    border-radius: 50px;
}

.dropdown-menu {
    background-color: #212529 !important;
    width: auto;
}

#input-group-dropdown-responsive {
    display: none;
}

#no-image {
    display: block;
}